<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <el-form>
          <el-form-item
              :label="$t('system.group_name')"
              required
          >
            <small
                class="help-block float-right"
                v-if="db_name.name"
            >
              {{ db_name.name.length }}/{{ maxlength }}
            </small>
            <small
                class="help-block float-right"
                v-else
            >
              0/{{ maxlength }}
            </small>
            <el-input
                :class="checkinputRequired('name') ? 'validate-error' : ''"
                :maxlength="maxlength"
                :placeholder="$t('system.group_name')"
                name="name"
                type="text"
                v-model="db_name.name"
            ></el-input>
            <small
                class="help-block"
                v-if="backend_errors.name && !checkinputRequired('name')"
            >
              <span v-html="validationTranslate(backend_errors.name[0])"></span>
            </small>
            <!--
            Forms with only one input behave strangelySolutions:
                a) remove the <form>
                b) add a hidden input (that you don’t have to use for anything)
            -->
            <input hidden>
          </el-form-item>
          <div class="box-button align-right pb-0 pr-0">
            <el-button
                :loading="loading"
                @click.prevent.stop="onSave"
                type="primary"
            >
              {{ $t('system.save') }}
            </el-button>
            <el-button @click.prevent.stop="backTo">
              {{ $t('system.cancel') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Global from '../../helpers/global';
import notification from '../../notification/notify';

let vm = null;

export default {
  name: 'GroupForm',
  components: {},
  data() {
    return {
      inputRequiredEmpty: [],
      backend_errors: {},
      db_name: {},
      maxlength: 30,
      option: {},
      title: 'Create',
      initialize: 'group/create',
      redirect: '/administration/group',
      store: 'group',
      method: 'post',
      loading: false,
    };
  },
  computed: {
    groupId: function () {
      return this.$store.getters.groupId;
    },
  },
  beforeMount() {
    if (this.$route.meta.mode === 'edit') {
      this.title = 'Edit';
      this.initialize = 'group/' + this.groupId + '/edit';
      this.store = 'group/' + this.groupId;
      this.method = 'put';
    }
    vm = this;
    this.fetchData();
  },
  mounted() {
    this.$emit('changeActiveLink');
  },
  watch: {
    '$route': 'fetchData',
  },
  methods: {
    backTo: function () {
      window.history.back();
    },
    updateCheckedPermissions(e) {
      this.checkedPermissions = e;
    },
    fetchData() {
      axios.get(this.initialize)
          .then(function (response) {
            vm.db_name = response.data.db_name;
            vm.option = response.data.option;
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    onSave() {
      let vm = this;
      vm.checkValidation().then(function () {
        Global.save(vm, vm);
      }).catch(function () {
        //
      });
    },
  },
};
</script>
